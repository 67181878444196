<template>
  <div class="thisRow">
    <div class="maxWidth">
      <div class="content">
        <div class="pos_look_up">
          <mobilePhone
              video="https://cdn.leagues.network/landingpage/app.mp4"
              :userInteraction="userInteraction"
              :sound="true"
              waypointPosition="bottom"
          />
        </div>
        <div class="flowingText">
          <h1 class="headline--large">Fussball wie nie zuvor!</h1>
          <h2 class="headline--semi">
            Das intensive Erlebnis auf einer eigenen Club-Plattform garantiert die maximale Aufmerksamkeit der Fans und Supporter.
          </h2>
          <div class="text">
            Spieltag ist Feiertag.<br /><span class="leagues_marker">LEAGUES</span> bringt Fans, Mitglieder und Unterstützer über das gemeinsame Erlebnis zusammen, um die reibungslose Unterstützung der Mannschaft und des Vereins zu ermöglichen. Und unvergessliche Geschichten zu erzählen.
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import mobilePhone from "@/components/elements/mobilephone";
export default {
  name: "row-kickoff",
  components: {mobilePhone},
  props: {
    userInteraction: Boolean
  },
}
</script>

<style lang="scss" scoped>
.pos_look_up{
  width: fit-content;
  display: inline-block;
  vertical-align: middle;
  top: 35px;
  @media (max-device-width: 600px) {
    text-align: center;
    width: 100%;
  }
  position: sticky !important;
  @media (min-device-width: 600px) {
    margin-top: -100px;
  }
}
.content {
  height: 80vh;
  @media (max-device-width: 600px) {
    height: 200vh;
 }
}
.thisRow{
  background: #000;
}
.flowingText{
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  @media (max-device-width: 600px) {
    background: rgba(31,31,31,0.7);
    color: #0afeff;
    position: relative;
    z-index: 1;
    padding: 20px;
    backdrop-filter: blur(5px);
  }
  @media (min-device-width: 600px) {
    padding-left: 10px;
    width: calc(100vh - 710px);
  }
  @media (min-device-width: 1020px) {
    width: 50%;
    padding-left: 100px;
  }
}
</style>
