<template>
  <div class="maxWidth">
    <div class="thisRow">
      <div class="formView">
        <div class="formView__left">
          <div class="formView__badge">
            Nur für offizielle Vereinsvertreter!
          </div>
          <div class="text">
            Unser Plattform-Team setzt sich mit Ihnen für die Details in Verbindung. Bitte haben Sie Verständnis dafür, dass wir nur mit offiziellen Vereinsvertretern den Start der Testphase besprechen können.
          </div>
          <form v-if="!form.ready">
            <input class="formView__input" type="text" placeholder="Verein" v-model="form.club" :class="{'form_error': form.club === '' && form.send}">
            <input class="formView__input" type="text" placeholder="Dein Name" v-model="form.name" :class="{'form_error': form.name === '' && form.send}">
            <input class="formView__input" type="text" placeholder="E-Mail oder Telefon" v-model="form.email" :class="{'form_error': form.email === '' && form.send}">
            <div>
              <div class="formView__subline">
                Interessante Features für uns:
              </div>
              <input type="radio" id="feature_livestreaming" name="feature" value="Live Streaming" class="formView__radio" v-model="form.feature"/>
              <label for="feature_livestreaming" class="formView__label">Live Streaming</label>

              <input type="radio" id="feature_ticker" name="feature" value="Video Ticker App"  class="formView__radio" v-model="form.feature"/>
              <label for="feature_ticker" class="formView__label">Video Ticker App</label>

              <input type="radio" id="feature_plattform" name="feature" value="Club-Eigene Plattform"  class="formView__radio" v-model="form.feature"/>
              <label for="feature_plattform" class="formView__label">Club-Eigene Plattform</label>
            </div>
            <div>
              <div class="button" @click="sendForm">
                Absenden
              </div>
              <div v-if="(this.form.email === '' || this.form.name === '' || this.form.club === '') && form.send" class="formView__error">
                Bitte alle Felder ausfüllen!
              </div>
            </div>
          </form>
          <div class="formView__success" v-else>
            Vielen Dank für dein Interesse!<br />
            Wir werden uns schnellstmöglich zurückmelden!
          </div>
          <Waypoint @change="onChange" :active="true"></Waypoint>
        </div>
        <div class="formView__right">
          <div class="headline--large" style="color: #FFF">
            KONTAKT
          </div>
          <div class="formView__company">
            LEAGUES GmbH<br />
            Fritz-Walter-Weg 19<br />
            70372 Stuttgart
          </div>
          <div class="formView__contact">
            <div class="contact-image">
              <img src="https://clubs.leagues.football/wp-content/uploads/2021/09/Markus.jpg" class=""/>
              <img src="https://clubs.leagues.football/wp-content/uploads/2021/09/Marcel_1_1.png" class=""/>
              <img src="https://clubs.leagues.football/wp-content/uploads/2022/06/Ebene-1.png" class=""/>
            </div>
            <div>
              <a href="https://www.linkedin.com/in/markus-kleber/">LinkedIn</a>
            </div>
            <div>
              <a href="mailto:contact@leagues.football">contact@leagues.football</a>
            </div>
            <div>
              <a href="tel:+491754179816">+49 175 417 98 16</a>
            </div>
          </div>
          <svg class="leagues_logo">
            <use xlink:href="#logo_vivid"></use>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {EventBus} from "@/main";
import { Waypoint } from "vue-waypoint";
export default {
  name: "row-contact",
  components: {
    Waypoint,
  },
  data() {
    return {
      firstAction: false,
      form: {
        error: false,
        send: false,
        ready: false,
        club: '',
        email: '',
        name: '',
        feature: ''
      }
    }
  },
  methods: {
    sendForm(){
      this.form.send = true;
      if(this.form.email === '' || this.form.name === '' || this.form.club === '' || this.form.feature === ''){
        this.form.error = true
      }else{
        this.form.error = false
      }
      if(!this.form.error){
        this.form.ready = true;
        axios.post('https://clubs.leagues.football/stuff/form/', {
          form: this.form,
        })
      }
    },
    onChange(waypointState){
      if(this.firstAction){
        if(waypointState.going === 'IN') {
          EventBus.$emit('showNav', true);
        }else{
          EventBus.$emit('showNav', false);
        }
      }
      this.firstAction = true;
    }
  }
}
</script>

<style lang="scss" scoped>
  .thisRow{
    background: #00464B;
    @media (min-device-width: 1020px) {
      //height: 100vh;
    }
  }
  .formView{
    padding: 40px;
    @media (min-device-width: 600px) {
      padding: 180px 40px 40px 40px;
    }
    color: #FFF;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
      grid-template-areas:
    "left"
    "right";
    @media (min-device-width: 600px) {
      grid-template-columns: 1.3fr 0.7fr;
      grid-template-areas:
    "left right";
    }
    .formView__left{
      grid-area: left;
      overflow: hidden;
      @media (min-device-width: 600px) {
        padding-right: 30px;
      }
    }
    .formView__right{
      grid-area: right;
      overflow: hidden;
      padding-top: 30px;
      @media (min-device-width: 600px) {
        padding-left: 30px;
        padding-top: 0;
      }
    }
    form{
      margin-top: 20px;
      border: 1px solid #FFF;
      padding: 10px;
      border-radius: 4px;
    }
  }
  .contact-image{
    position: relative;
    height: 30vw;
    @media (min-device-width: 600px) {
      height: 200px;
    }
    margin: 20px 0;
    img:first-child{
      left: 0;
      z-index: 3;
    }
    img:nth-child(2){
      left: 20vw;
      @media (min-device-width: 600px) {
        left: 120px;
      }
      z-index: 2;
    }
    img:nth-child(3){
      left: 40vw;
      @media (min-device-width: 600px) {
        left: 240px;
      }
      z-index: 1;
    }
    img{
      border-radius: 100%;
      width: 30vw;
      height: 30vw;
      border: 5px solid #FFF;
      @media (min-device-width: 600px) {
        width: 180px;
        height: 180px;
      }
      position: absolute;
    }
  }
  .formView__input{
    padding: 10px;
    width: calc(100% - 25px);
    margin: 10px 0;
    outline: none;
    border: 2px solid #FFF;
    font-size: 1.2rem;
    border-radius: 4px;
  }
  .formView__badge{
    color: #00464B;
    background: #ffffff;
    display: inline-block;
    padding: 10px;
    font-weight: bold;
    text-transform: uppercase;
  }
  .formView__subline{
    font-weight: bold;
    margin: 10px 0;
  }
  .formView__radio{
    position: absolute;
    left: -300vw;
  }
  .formView__label{
    display: block;
    vertical-align: center;
    line-height: 1;
    font-size: 1.2rem;
    margin-bottom: 20px;
    position: relative;
    overflow: hidden;
    height: 2rem;
    cursor: pointer;
    &:hover{
      color: rgba(255,255,255,0.5);
    }
    &:before{
      content: '';
      border-radius: 100%;
      width: 20px;
      height: 20px;
      border: 2px solid #FFF;
      display: inline-block;
      position: relative;
      top: 5px;
      margin-right: 10px;
    }
    &:after{
      content: '';
      border-radius: 100%;
      width: 10px;
      height: 10px;
      background: #FFF;
      display: inline-block;
      position: absolute;
      left: -70px;
      top: 10px;
      margin-right: 10px;
      transition: all ease-out .5s;
    }
  }
  .formView__radio:checked + .formView__label{
    &:after{
      left: 5px;
    }
  }
  .formView__company{
    font-size: 1.2rem;
    line-height: 1.5;
    margin-bottom: 10px;
  }
  .formView__contact{
    a{
      color: #ffffff;
      display: inline-block;
      font-size: 1.2rem;
      text-decoration: none;
      overflow: hidden;
      position: relative;
      height: 1.5rem;
      margin-bottom: 10px;
      &:after{
        content: '';
        display: inline-block;
        width: 100%;
        height: 2px;
        bottom: 0px;
        left: 0;
        position: absolute;
        background: #ffffff;
        transition: width ease-in-out 1s;
      }
      &:hover{
        &:after{
          width: 0px;
        }
      }
    }
  }
  .button{
    background: #FFF;
    text-align: center;
    padding: 20px;
    border-radius: 4px;
    font-weight: bold;
    color: #00464B;
    text-transform: uppercase;
    cursor: pointer;
  }
  .form_error{
    background: rgba(153,0,0,0.4);
    border: 2px solid #FFF;
  }
  .formView__error{
    font-weight: bold;
    font-size: 0.8rem;
    margin: 5px 0;
  }
  .formView__success{
    font-size: 1.2rem;
    line-height: 1.5;
    background: #000000;
    padding: 10px;
    margin: 10px 0;
  }
  .leagues_logo{
    width: 100%;
    svg{
      width: 100%;
    }
  }
</style>
