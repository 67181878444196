<template>
  <div class="maxWidth">
    <div class="thisRow">
      <div class="flowingText">
        <h1 class="headline--verylarge">Die Club-eigene Erlebnisplattform.</h1>
      </div>
      <div class="look-down">
        <mobilephone video="https://cdn.leagues.network/landingpage/clubowned.mp4"
                     :userInteraction="userInteraction"
                     :sound="false"
                     waypointPosition="top"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Mobilephone from "@/components/elements/mobilephone";
export default {
  name: "row-ownplattform",
  components: {Mobilephone},
  props: {
    userInteraction: Boolean
  }
}
</script>

<style lang="scss" scoped>
  .thisRow{
    background-image: url('../../assets/pyro.jpg');
    background-size: cover;
    min-height: 100vh;
    text-align: center;
    overflow: hidden;
    background-attachment: fixed;
    @media (max-device-width: 600px) {
      padding-top: 60px;
    }
  }
  .look-down{
    //position: relative;
    //bottom: calc((80vh - 600px) * -1);
    @media (max-device-width: 600px) {
      margin: 100px 0;
    }
    @media (min-device-width: 600px) {
      bottom: 0;
      left: 50%;
      transform: translate(-50%, -100px);
      position: absolute;
    }
  }
</style>
