var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"}},[_c('nav',{class:{'hideNav': _vm.hideNav}},[_c('svg',{staticClass:"leaguesLogo"},[_c('use',{attrs:{"xlink:href":"#logo_vivid"}})]),_c('div',{staticClass:"hamburger",on:{"click":function($event){_vm.showNav = !_vm.showNav}}},[_c('span')]),_c('ul',{staticClass:"navigation",class:{'navigation__show': _vm.showNav}},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5)])]),_c('div',{staticClass:"stickyNav"},[_c('svg',{staticClass:"leaguesLogo"},[_c('use',{attrs:{"xlink:href":"#logo_vivid"}})])]),_c('div',[_c('div',{ref:"leagues",staticClass:"leagues",on:{"scroll":_vm.handleScroll}},[_c('section',{staticClass:"section-fixed"},[_c('row-opener',{attrs:{"userInteraction":_vm.userInteraction}})],1),_c('section',{ref:"kickoff",attrs:{"id":"kickoff"}},[_c('row-kickoff',{attrs:{"userInteraction":_vm.userInteraction}})],1),_c('section',[_c('row-products',{attrs:{"userInteraction":_vm.userInteraction}})],1),_c('section',[_c('row-ownplattform',{attrs:{"userInteraction":_vm.userInteraction}})],1),_c('section',[_c('row-contact',{attrs:{"userInteraction":_vm.userInteraction}})],1),_vm._m(6)])]),_c('the-s-v-g-sprite')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"navigation__item"},[_c('a',{attrs:{"href":""}},[_vm._v("App")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"navigation__item"},[_c('a',{attrs:{"href":""}},[_vm._v("Livestream")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"navigation__item"},[_c('a',{attrs:{"href":""}},[_vm._v("Aktuell")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"navigation__item"},[_c('a',{attrs:{"href":""}},[_vm._v("Team")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"navigation__item"},[_c('a',{attrs:{"href":""}},[_vm._v("Über uns")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"navigation__item"},[_c('a',{attrs:{"href":""}},[_vm._v("Jobs")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('footer',[_c('a',{attrs:{"href":"https://www.leagues.football/imprint","target":"_blank"}},[_vm._v("Impressum")]),_vm._v(" | "),_c('a',{attrs:{"href":"https://www.leagues.football/datenschutz","target":"_blank"}},[_vm._v("Datenschutz")])])
}]

export { render, staticRenderFns }